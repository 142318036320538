/* Sticky Footer */
/* See comments: https://css-tricks.com/couple-takes-sticky-footer/ */
html {
  height: 100%;
  box-sizing: border-box;
}

body {
  min-height: 100%;
  position: relative;
}

.Footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  /* padding: 0 12px 12px 12px; */
  padding: 12px;
  background: var(--primary);
  text-align: center;
}

.FooterText {
  font-family: 'Lato';
  color: #ffeeff;
  letter-spacing: 1.5px;
  font-size: 14px;
}
/* Sticky Footer End */
