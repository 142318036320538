/* Fix React PDF Viewer Error where after hitting escape to exit full screen,
you get an error. This fixes the error:
(Source: https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications) */

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

:root {
  --primary: #333333;
  --background: #f7f7f7;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Utilities */
.container {
  /* max-width: 1100px; */
  margin: auto;
  overflow: hidden;
  /* padding: 0 2rem; */
}

body {
  letter-spacing: 1px;
  /* background-color: #e6eaea !important; */
  background-color: var(--background) !important;
}

h3 {
  font-family: 'Quicksand', sans-serif;
}

.content-div {
  margin-bottom: 80px;
}

/* .click {
  color: #3e63bd;
} */

/* a {
  color: #ffeeff;
  text-decoration: none;
  background-color: transparent;
  padding: 0 10px;
} */

a:hover {
  color: #ff6347;
  text-decoration: none;
}

/* Sticky (But Not Fixed) Footer,
        Source: https://chrisbracco.com/css-sticky-footer-effect/ */
/* .footer {
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  padding: 12px;
  background: #ff6347;
  text-align: center;
} */
/* ========================= */

/* #footer-text {
  font-family: 'Quicksand', sans-serif;
  color: #ffeeff;
  letter-spacing: 1.5px;
  font-size: 16px;
  margin-top: 5px;
} */
/* End Footer */

/* #error-msg,
#success-msg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#guides-btn,
#notes-btn,
#error-btn,
#submit-btn,
#back-btn {
  font-family: 'Quicksand', sans-serif;
  background: #ff6347;
  border-color: #ff6347;
  margin-right: 5px;
  margin-bottom: 40px;
} */
