.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  background-color: var(--primary);
  margin-bottom: 1rem;
}

.NavTitle {
  font-family: 'Oxygen', sans-serif;
  color: var(--background) !important;
  font-size: 26px;
  letter-spacing: 0.1em;
  padding-bottom: 0.5em;
  margin-top: 2.5px;
  /* text-transform: uppercase; */
}

ul {
  padding: 0 16px 0 0;
}

ol,
ul {
  padding-left: 0;
}

.Links {
  margin-bottom: -15px;
  float: inherit;
  /* color: #c7d1d8 !important; */
  font-family: 'Lato';
  letter-spacing: 0.14em;
}

.Links a,
.Title {
  /* color: #ffeeff !important; */
  color: #a1a2a6 !important;
  text-decoration: none !important;
  background-color: transparent;
  padding: 0 10px;
}

.Navbar ul {
  display: flex;
}

.Navbar li {
  list-style: none;
}

.Navbar a {
  color: #dedede;
  font-size: 18px;
  margin: 0 0.25rem;
  margin-bottom: -25px;
  padding: 10px;
}

.Navbar a:hover {
  color: #ffeeff !important;
}

/* ===== MEDIA QUERIES ===== */

@media screen and (min-width: 981px) and (max-width: 1200px) {
  /* body {
    background-color: red;
  } */

  .NavTitle {
    padding-bottom: 0.5em;
  }
}

@media screen and (min-width: 769px) and (max-width: 980px) {
  .NavTitle {
    padding-bottom: 0.5em;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .Navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .NavTitle {
    margin-top: -10px;
    margin-bottom: -30px;
  }

  .Navbar ul {
    text-align: center;
    justify-content: center;
  }
}

@media screen and (min-width: 551px) and (max-width: 640px) {
  .Navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .NavTitle {
    margin-top: -10px;
    margin-bottom: -30px;
  }

  .Navbar ul {
    text-align: center;
    justify-content: center;
  }
}

@media screen and (min-width: 471px) and (max-width: 550px) {
  .Navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .NavTitle {
    margin-top: -10px;
    margin-bottom: -30px;
  }

  .Navbar ul {
    text-align: center;
    justify-content: center;
  }

  .Links {
    font-size: 8px;
  }
}

@media screen and (min-width: 431px) and (max-width: 470px) {
  .Navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .NavTitle {
    font-size: 24px;
    margin-top: -10px;
    margin-bottom: -30px;
  }

  .Navbar a {
    font-size: 16px;
  }

  .Navbar ul {
    text-align: center;
    justify-content: center;
  }

  .Links {
    font-size: 8px;
  }
}

@media screen and (min-width: 401px) and (max-width: 430px) {
  .Navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .NavTitle {
    font-size: 24px;
    margin-top: -10px;
    margin-bottom: -30px;
  }

  .Navbar a {
    font-size: 16px;
  }

  .Navbar ul {
    text-align: center;
    justify-content: center;
  }

  .Links {
    font-size: 8px;
  }
}

@media screen and (min-width: 100px) and (max-width: 400px) {
  .Navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .NavTitle {
    font-size: 24px;
    margin-top: -10px;
    margin-bottom: -30px;
  }

  .Navbar a {
    font-size: 16px;
  }

  .Navbar ul {
    text-align: center;
    justify-content: center;
  }

  .Navbar a {
    font-size: 12.5px;
  }

  .Links {
    font-size: 8px;
  }
}
