.Container {
  font-family: 'Lato';
  font-size: 16px;
  letter-spacing: 1px;
  /* line-height: 180%; */
  /* line-height: 160%; */
}

.PageTitle {
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
}

.PageSubtitle {
  font-weight: 400;
  line-height: 1.5;
}

/* Accordion Styles */
.Accordion {
  margin: 2rem auto;

  margin-bottom: 0;
  margin-top: 0;
}

.AccordionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

/* .AccordionTitle,
.AccordionContent {
  padding: 1rem 0;
} */

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .Accordion {
    width: 90%;
  }
}
/* End Accordion Styles */

/* .CardContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
} */

.CardContainer {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding: 1%;
  gap: 5%;
}

.Card {
  width: 20rem;
  margin-bottom: 5%;
  /* margin-bottom: 15%; */
}

.ProjectName {
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}

.SeeMoreBtn {
  width: 50%;

  letter-spacing: 1px;
  background-color: var(--primary) !important;
  color: #ffffff !important;
  padding: 6px 12px;
  border-radius: 0.25rem;
  border: 1px solid var(--primary) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25); */
}

.SeeMoreBtn:hover {
  background-color: #ffffff !important;
  color: var(--primary) !important;
  text-decoration: none;
}

.SeeMoreBtn:active {
  background-color: var(--primary) !important;
  color: #ffffff !important;
  border: 1px solid var(--primary) !important;
  outline: none !important;
  box-shadow: none !important;
}

/* ===== MEDIA QUERIES ===== */

@media screen and (min-width: 981px) and (max-width: 1200px) {
  /* .Container {
    background-color: red;
  } */

  .CardContainer {
    justify-content: center;
  }

  /* .Card {
    width: 18rem;
  } */
}

@media screen and (min-width: 769px) and (max-width: 980px) {
  /* .Container {
    background-color: yellow;
  } */
}

@media screen and (max-width: 991px) {
  /* .Container {
    background-color: steelblue;
  } */

  .CardContainer {
    justify-content: center;
  }
}
