.Container {
  font-family: 'Lato';
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 180%;
}

.PageTitle {
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 36px;
  /* text-transform: uppercase; */
}

.LinkInIcon {
  color: #0a66c2 !important;
  font-size: 20px;
}

.SendBtn {
  letter-spacing: 1px;
  background-color: var(--primary) !important;
  color: #ffffff !important;
  padding: 6px 12px;
  border-radius: 0.25rem;
  border: 1px solid var(--primary) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.SendBtn:hover {
  background-color: #ffffff !important;
  color: var(--primary) !important;
  text-decoration: none;
}

.SendBtn:active {
  background-color: var(--primary) !important;
  color: #ffffff !important;
  border: 1px solid var(--primary) !important;
  outline: none !important;
  box-shadow: none !important;
}
