.BackBtn {
  letter-spacing: 1px;
  background-color: var(--primary) !important;

  color: #ffffff !important;
  padding: 6px 12px;
  border-radius: 0.25rem;
  border: 1px solid var(--primary) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25); */
  text-decoration: none;
}

.BackBtn:hover {
  background-color: #ffffff !important;
  color: var(--primary) !important;
  text-decoration: none;
}

.BackBtn:active {
  background-color: var(--primary) !important;
  color: #ffffff !important;
  border: 1px solid var(--primary) !important;
  outline: none !important;
  box-shadow: none !important;
}
