.Container {
  font-family: 'Lato';
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 180%;
}

.PageTitle {
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
  /* text-align: center; */
  font-size: 36px;
  /* text-transform: uppercase; */
}

.ImgDiv {
  margin-right: -80px;
  margin-bottom: 20px;
}

.ProfileImage {
  width: 75% !important;
}

.Icon {
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
